import React from 'react';
import Inform1 from './Inform1';
import Inform2 from './Inform2';
import Inform3 from './Inform3';
import Inform4 from './Inform4';
import Mission from './Mission';
import HomeTitle from './HomeTitle';
import './Home.css';

const Home = () => {
  return (
    <div>
      {/* Inform1 section */}
      <div className="inform-section">
        <HomeTitle />
        <Inform1 />
        <Inform2 />
        <Inform3 />
        <Mission />
        <Inform4 />

      </div>
    </div>
  );
};

export default Home;
