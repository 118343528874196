import React from 'react';
import './Slideshow.css';

// Import images
import image1 from '../assets/side1.png';
import image2 from '../assets/side2.png';
import image3 from '../assets/side3.jpg';

const images = [image1, image2, image3];

const Slideshow = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000); // Change image every 6 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slideshow">
      <div className="slide">
        <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
      </div>
    </div>
  );
};

export default Slideshow;
