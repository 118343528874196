// Mission.jsx
import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Mission.css'; // Ensure this is the correct path
import missionImage from '../assets/mission1.jpg'; // Replace with the actual image path

const Mission = () => {
  const [inView, setInView] = useState(false);
  const imageRef = useRef(null);
  const contentRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const imageRect = imageRef.current.getBoundingClientRect();
      const contentRect = contentRef.current.getBoundingClientRect();
      const inImageView = imageRect.top <= window.innerHeight && imageRect.bottom >= 0;
      const inContentView = contentRect.top <= window.innerHeight && contentRect.bottom >= 0;
      
      setInView(inImageView || inContentView);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check on mount

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className={`mission ${inView ? 'in-view' : ''}`}>
      <div className={`mission-top ${inView ? 'top-in-view' : ''}`}>
        <h1>{t('mv.title')}</h1>
      </div>
      <div className={`mission-bottom ${inView ? 'bottom-in-view' : ''}`}>
      <div className='mission-image' ref={imageRef}>
        <img src={missionImage} alt="Mission" />
      </div>
      <div className='mission-content' ref={contentRef}>
        <div className="mission-content-top">
          <h1>{t('mv.mission.title')}</h1>
          <hr />
          <p>
          <span className="highlight">{t('mv.mission.span')}</span>
          {t('mv.mission.text')}
          </p>
        </div>
        <br />
        <div className="mission-content-bottom">
          <h1>{t('mv.vision.title')}</h1>
          <hr />
          <p>
          <span className="highlight">{t('mv.vision.span')}</span>
          {t('mv.vision.text')}
          </p>
        </div>
      </div>
      </div>
    </section>
  );
};

export default Mission;
