import React from 'react';
import { useTranslation } from 'react-i18next';
import './TablePage.css'; // Import the CSS file

const TablePage = () => {
    const { t } = useTranslation();
    return (
        <div className="table-container">
            <h5 className="table-header">{t('table.title')}</h5>
            <table>
                <thead>
                    <tr>
                        <th className="column1"></th>
                        <th className="column2">{t('table.header.colomn1')}</th>
                        <th className="column3">{t('table.header.colomn2')}</th>
                        <th className="column4">{t('table.header.colomn3')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="column1">{t('table.row1.colomn1')}</td>
                        <td className="column2">{t('table.row1.colomn2')}</td>
                        <td className="column3">{t('table.row1.colomn3')}</td>
                        <td className="column4">{t('table.row1.colomn4')}</td>
                    </tr>
                    <tr>
                        <td className="column1">{t('table.row2.colomn1')}</td>
                        <td className="column2">{t('table.row2.colomn2')}</td>
                        <td className="column3">{t('table.row2.colomn3')}</td>
                        <td className="column4">{t('table.row2.colomn4')}</td>
                    </tr>
                    <tr>
                        <td className="column1">{t('table.row3.colomn1')}</td>
                        <td className="column2">{t('table.row3.colomn2')}</td>
                        <td className="column3">{t('table.row3.colomn3')}</td>
                        <td className="column4">{t('table.row3.colomn4')}</td>
                    </tr>
                    <tr>
                        <td className="column1">{t('table.row4.colomn1')}</td>
                        <td className="column2">{t('table.row4.colomn2')}</td>
                        <td className="column3">{t('table.row4.colomn3')}</td>
                        <td className="column4">{t('table.row4.colomn4')}</td>
                    </tr>
                    <tr>
                        <td className="column1">{t('table.row5.colomn1')}</td>
                        <td className="column2">{t('table.row5.colomn2')}</td>
                        <td className="column3">{t('table.row5.colomn3')}</td>
                        <td className="column4">{t('table.row5.colomn4')}</td>
                    </tr>
                    <tr>
                        <td className="column1">{t('table.row6.colomn1')}</td>
                        <td className="column2">{t('table.row6.colomn2')}</td>
                        <td className="column3">{t('table.row6.colomn3')}</td>
                        <td className="column4">{t('table.row6.colomn4')}</td>
                    </tr>
                    <tr>
                        <td className="column1">{t('table.row7.colomn1')}</td>
                        <td className="column2">{t('table.row7.colomn2')}</td>
                        <td className="column3">{t('table.row7.colomn3')}</td>
                        <td className="column4">{t('table.row7.colomn4')}</td>
                    </tr>
                    <tr>
                        <td className="column1">{t('table.row8.colomn1')}</td>
                        <td className="column2">{t('table.row8.colomn2')}</td>
                        <td className="column3">{t('table.row8.colomn3')}</td>
                        <td className="column4">{t('table.row8.colomn4')}</td>
                    </tr>
                    <tr>
                        <td className="column1">{t('table.row9.colomn1')}</td>
                        <td className="column2">{t('table.row9.colomn2')}</td>
                        <td className="column3">{t('table.row9.colomn3')}</td>
                        <td className="column4">{t('table.row9.colomn4')}</td>
                    </tr>
                    <tr>
                        <td className="column1">{t('table.row10.colomn1')}</td>
                        <td className="column2">{t('table.row10.colomn2')}</td>
                        <td className="column3">{t('table.row10.colomn3')}</td>
                        <td className="column4">{t('table.row10.colomn4')}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default TablePage;
