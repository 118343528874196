import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Endcard.css'; // Import the CSS file

const Application = () => {
  const { t } = useTranslation();
  const [inView, setInView] = useState(false);
  const appRef = useRef(null);

  useEffect(() => {
    const currentRef = appRef.current; // Store the current ref value

    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.1 } // Adjust as needed
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Use the stored ref value in cleanup
      }
    };
  }, []);
  return (
    <div ref={appRef} className={`endcard-page ${inView ? 'in-view' : ''}`}>
      {/* Left-side div with headline and text */}
      <div className="endcard-container">
        <div className="endline">
          <h1>{t('endcard.title1')}<br />
          {t('endcard.title2')}</h1>
        </div>
      </div>
    </div>
  );
};

export default Application;
