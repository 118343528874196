import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Inform4.css'; // Ensure this is the correct path
import Inform4 from '../assets/inform4.png';

const Inform = () => {
  const [inView, setInView] = useState(false);
  const informRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const currentRef = informRef.current; // Store the current ref value

    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.1 } // Adjust as needed
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Use the stored ref value in cleanup
      }
    };
  }, []);

  return (
    <section ref={informRef} className={`inform4 ${inView ? 'in-view' : ''}`}>
      <div className="inform4-left">
        <h2>{t('inform4.title')}</h2>
        <ul className="bullet-points">
          <li>
          {t('inform4.point1.text1')}<span className="highlight">{t('inform4.point1.span1')}</span>{t('inform4.point1.text2')}<span className="highlight">{t('inform4.point1.span2')}</span>
          </li>
          <li>
          {t('inform4.point2.text1')}<span className="highlight">{t('inform4.point2.span1')}</span>
          </li>
          <li>
            <span className="highlight">{t('inform4.point3.span1')}</span>{t('inform4.point3.text1')}<span className="highlight">{t('inform4.point3.span2')}</span>{t('inform4.point3.text2')}
          </li>
          <li>
          {t('inform4.point4.text1')}
          </li>
        </ul>
      </div>
      <div className="inform4-right">
        <img src={Inform4} alt="inform4" />
      </div>
    </section>
  );
};

export default Inform;
