import React from 'react';
import Df3 from './Df3';


const Product = () => {
  return (
    <div>
      <div className="inform-section">
        <br />
        <Df3 />

      </div>
    </div>
  );
};

export default Product;
