import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './Navbar.css';
import LanguageSwitcher from './LanguageSwitcher';
import Logo from '../assets/Logo1.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [visible, setVisible] = useState(true); 
  const { t } = useTranslation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Handle scroll behavior
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        // User is scrolling down, hide the navbar
        setVisible(false);
      } else {
        // User is scrolling up, show the navbar
        setVisible(true);
      }

      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop); // Ensure scroll doesn't go below 0
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  return (
    <nav className={`navbar ${visible ? 'visible' : 'hidden'}`}>
      {/* Company logo on the left side */}
      <div className={`logo ${isOpen ? 'small' : ''}`}>
        <a href="#home">
        <Link to="/">
          <img src={Logo} alt="Company Logo" className="logo-img" />
        </Link>
        </a>
      </div>

      {/* Menu links on larger screens */}
      <ul className={`nav-links ${isOpen ? 'nav-active' : ''}`}>
        <li><a href="#home"><Link to="/">{t('navbar.link1')}</Link></a></li>
        <li><a href="#product"><Link to="/product">{t('navbar.link2')}</Link></a></li>
        <li><a href="#about"><Link to="/about">{t('navbar.link3')}</Link></a></li>
        <li><a href="#application"><Link to="/application">{t('navbar.link4')}</Link></a></li>
        <li><button className="contact-btn"><Link to="/contact">{t('navbar.link5')}</Link></button></li>
        <li>
          <LanguageSwitcher />
        </li>
      </ul>

      {/* Mobile navicon for small screens */}
      <div className="nav-icon" onClick={toggleMenu}>
        <div className={`burger ${isOpen ? 'toggle' : ''}`}>
          <span className="burger-line"></span>
          <span className="burger-line"></span>
          <span className="burger-line"></span>
        </div>
      </div>

      {/* Mobile menu */}
      <ul className={`mobile-menu ${isOpen ? 'open' : ''}`}>
        <li><a href="#home" onClick={toggleMenu}><Link to="/">{t('navbar.link1')}</Link></a></li>
        <li><a href="#product" onClick={toggleMenu}><Link to="/product">{t('navbar.link2')}</Link></a></li>
        <li><a href="#about" onClick={toggleMenu}><Link to="/about">{t('navbar.link3')}</Link></a></li>
        <li><a href="#application" onClick={toggleMenu}><Link to="/application">{t('navbar.link4')}</Link></a></li>
        <li><button className="contact-btn" onClick={toggleMenu}><Link to="/contact">{t('navbar.link5')}</Link></button></li>
        <li>
          <LanguageSwitcher />
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
