import React from 'react';
import Application1 from './Application1';
import Application2 from './Application2';
import Application3 from './Application3';
import Application4 from './Application4';
import Application5 from './Application5';
import Application6 from './Application6';
import Endcard from './Endcard';
import ApplicationTitle from './ApplicationTitle';

const Application = () => {
  return (
    <div>
      <div className="inform-section">
        <ApplicationTitle />
        <br />
        <Application1 />
        <Application2 />
        <Application3 />
        <Application4 />
        <Application5 />
        <Application6 />
        <Endcard />

      </div>
    </div>
  );
};

export default Application;
