import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Df3_inform2.css';
import Image2 from '../assets/Df4_inform2.png';

const ProductPage2 = () => {
  const { t } = useTranslation();
  useEffect(() => {
    const productPage = document.querySelector('.product-page2');

    const handleScroll = () => {
      const rect = productPage.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom >= 0) {
        productPage.classList.add('in-view');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="product-page2">
      {/* Top section with headline and image */}
      <div className="top-section2">
        <h1 className="product_headline2">{t('df3inform2.title')}</h1>
        <img src={Image2} alt="Product" className="product-image2" />
      </div>

      {/* Bottom section with left and right text divs */}
      <div className="bottom-section2">
        <div className="left-text2">
          <h2>{t('df3inform2.feature1.sub-title')}</h2>
          <hr />
        <ul className="product-points2">
          <li>
            <span className="feature2">{t('df3inform2.feature1.point1.span')}</span>{t('df3inform2.feature1.point1.text')}
          </li>
          <li>
            <span className="feature2">{t('df3inform2.feature1.point2.span')}</span>{t('df3inform2.feature1.point2.text')} 
          </li>
          <li>
            <span className="feature2">{t('df3inform2.feature1.point3.span')}</span>{t('df3inform2.feature1.point3.text')}
          </li>
        </ul>
        </div>
        <div className="right-text2">
        <h2>{t('df3inform2.feature2.sub-title')}</h2>
          <hr />
        <ul className="product-points2">
          <li>
            <span className="feature2">{t('df3inform2.feature2.point1.span')}</span>{t('df3inform2.feature2.point1.text')}
          </li>
          <li>
            <span className="feature2">{t('df3inform2.feature2.point2.span')}</span>{t('df3inform2.feature2.point2.text')} 
          </li>
          <li>
            <span className="feature2">{t('df3inform2.feature2.point3.span')}</span>{t('df3inform2.feature2.point3.text')}
          </li>
          <li>
            <span className="feature2">{t('df3inform2.feature2.point4.span')}</span>{t('df3inform2.feature2.point4.text')}
          </li>
        </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductPage2;
