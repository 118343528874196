import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import './HomeTitle.css';

const HomeTitle = () => {
  const { t } = useTranslation(); // Initialize translation

  return (
    <div className="home-overlay">
      <h1 className="home-heading">
        {t('homeTitle.welcome')} <br />
        <span className='home-hightlights'>{t('homeTitle.company')}</span>
      </h1>
    </div>
  );
};

export default HomeTitle;
