import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation(); // useTranslation hook to access i18n instance

  // Function to switch language
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div>
      <button onClick={() => changeLanguage('en')} className="lan-btn">English</button>
      <button onClick={() => changeLanguage('es')} className="lan-btn">Español</button>
    </div>
  );
};

export default LanguageSwitcher;
