import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './CertificatePage.css';
import Certificate1 from '../assets/certificate1.png';
import Certificate2 from '../assets/certificate2.png';

const CertificatePage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    const pageContent = document.querySelector('.Certificate-content');

    const handleScroll = () => {
      const rect = pageContent.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom >= 0) {
        pageContent.classList.add('in-view');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="Certificate-content">
      {/* Header Section */}
      <header className="Certificate-header">
        <h1 className="Certificate-title">{t('certificate.header')}</h1>
      </header>

      {/* Content Section */}
      <div className="Certificate-section">
        <div className="left-Certificate">
          <img src={Certificate1} alt="Left" className="Certificate-image1" />
          <p>{t('certificate.certificate1.text1')}<br /> <br />
          {t('certificate.certificate1.text2')}<br />
          {t('certificate.certificate1.text3')} 
          </p>
        </div>
        <br />
        <div className="right-Certificate">
          <img src={Certificate2} alt="Right" className="Certificate-image2" />
          <p>{t('certificate.certificate2.text1')}<br />
          {t('certificate.certificate2.text2')}<br />
          {t('certificate.certificate2.text3')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CertificatePage;
