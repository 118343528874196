import React from 'react';
import { useTranslation } from 'react-i18next';
import './Contact.css'; // Ensure this is the correct path
import ContactTitle from './ContactTitle';

const Contact = () => {
  const { t } = useTranslation();
  return (
    <section className="contact">
      <ContactTitle />
      <div className="contact-top">
        <h1 className="contact-heading">{t('contactus.title')}</h1>
      </div>
      <div className="contact-bottom">
        <div className="contact-info contact-info-animation">
          <h1>{t('contactus.contact1.title1')}<br />
          {t('contactus.contact1.title2')}</h1>
          <p>
          {t('contactus.contact1.text1')}<br />
          {t('contactus.contact1.text2')}<br />
          {t('contactus.contact1.text3')}<br />
          {t('contactus.contact1.text4')}
          </p>
        </div>
        <div className="contact-info contact-info-animation">
        <h1>{t('contactus.contact2.title1')}<br />
        {t('contactus.contact2.title2')}</h1>
          <p>
          {t('contactus.contact1.text1')}<br />
          {t('contactus.contact1.text2')}<br />
          {t('contactus.contact1.text3')}<br />
          {t('contactus.contact1.text4')}
          </p>
        </div>
        <div className="contact-info contact-info-animation">
        <h1>{t('contactus.contact3.title1')}<br />
        {t('contactus.contact3.title2')}</h1>
          <p>
          {t('contactus.contact1.text1')}<br />
          {t('contactus.contact1.text2')}<br />
          {t('contactus.contact1.text3')}<br />
          {t('contactus.contact1.text4')}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
