import React from 'react';
import { useTranslation } from 'react-i18next';
import './LearnPage.css';

const LearnPage = () => {
  const { t } = useTranslation();
  return (
    <div className="learn-page">
      {/* Left-side div with headline and text */}
      <div className="learn-content-container">
        <div className="learn-headline">
          <h1>{t('learn.title')}</h1>
          <h2>{t('learn.sub-title')}</h2>
        </div>
        <div className="learn-text">
          <ul className="learn-points">
            <li>
            {t('learn.learn1.point1')}
            </li>
            <li>
            {t('learn.learn1.point2')}
            </li>
            <li>
            {t('learn.learn1.point3')}
            </li>
            <li>
                <span className='learn-highlight'>{t('learn.learn1.point4')}</span>
            </li>
          </ul>
          <ul>
              <li className="learn-points2">{t('learn.learn2.point1')}</li>
              <li className="learn-points2">{t('learn.learn2.point2')}</li>
              <li className="learn-points2">{t('learn.learn2.point3')}</li>
              <li className="learn-points2">{t('learn.learn2.point4')}</li>
              <li className="learn-points2">{t('learn.learn2.point5')}</li>
              <li className="learn-points2">{t('learn.learn2.point6')}</li>
            </ul>
        </div>
      </div>
    </div>
  );
};

export default LearnPage;
