import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Slideshow from './components/Slideshow';
import Home from './Home/Home';
import Application from './Application/Application';
import TeamPage from './Team/TeamPage';
import ProductPage from './Product/Product';
import ContactPage from './Contact/Contact';

import './App.css';

function App() {
  return (
    <Router>
      <Navbar />
      <Slideshow />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/application" element={<Application />} />
        <Route path="/about" element={<TeamPage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </Router>
  );
}

export default App;
