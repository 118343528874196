import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './TeamPage.css'; // Import the CSS file for the styles
import member1 from '../assets/member1.jpg';
import member2 from '../assets/member2.jpg';
import member3 from '../assets/member3.jpg';
import TeamTitle from './TeamTitle';

const Team = () => {
  const { t } = useTranslation();
  // To handle flipping of individual cards
  const [flipped, setFlipped] = useState([false, false, false]);

  // Toggle flip state of a specific card
  const handleFlip = (index) => {
    const updatedFlips = [...flipped];
    updatedFlips[index] = !updatedFlips[index];
    setFlipped(updatedFlips);
  };

  return (
    <div>
      <TeamTitle />
    <div className="team-page">
      <h1 className="team-title">{t('team.title')}</h1>
      <div className="team-cards">
        <div className={`card ${flipped[0] ? 'flipped' : ''}`} onClick={() => handleFlip(0)}>
          <div className="card-front">
            <img className="team-image" src={member1} alt="Team Member" />
            <h2>{t('team.member1.title1')}<br />{t('team.member1.title2')}</h2>
          </div>
          <div className="card-back">
            <h1>{t('team.member1.name')}</h1>
            <div className='card-details'>
              <h2>{t('team.member1.text1')}</h2>
              <h2>{t('team.member1.text2')}</h2>
              <h2>{t('team.member1.text3')}</h2>
            </div>
          </div>
        </div>

        <div className={`card ${flipped[1] ? 'flipped' : ''}`} onClick={() => handleFlip(1)}>
          <div className="card-front">
            <img className="team-image" src={member2} alt="Team Member" />
            <h2>{t('team.member2.title1')}<br />{t('team.member2.title2')}</h2>
          </div>
          <div className="card-back">
              <h1>{t('team.member2.name')}</h1>
              <div className='card-details'>
              <h2>{t('team.member2.text1')}</h2>
              <h2>{t('team.member2.text2')}</h2>
              <h2>{t('team.member2.text3')}</h2>
            </div>
          </div>
        </div>

        <div className={`card ${flipped[2] ? 'flipped' : ''}`} onClick={() => handleFlip(2)}>
          <div className="card-front">
            <img className="team-image" src={member3} alt="Team Member" />
            <h2>{t('team.member3.title1')}</h2><br />
          </div>
          <div className="card-back">
              <h1>{t('team.member3.name')}</h1>
              <div className='card-details'>
              <h2>{t('team.member3.text1')}</h2>
              <h2>{t('team.member3.text2')}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Team;
