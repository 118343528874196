import React from 'react';
import { useTranslation } from 'react-i18next';
import './ContactTitle.css'; // Import the corresponding CSS file

const TitlePage = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-overlay">
      <h1 className="contact-us">{t('contactTitle.title')}</h1>
      <p className="contact-description">{t('contactTitle.description')}</p>
      
    </div>
  );
};

export default TitlePage;
