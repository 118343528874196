import React from 'react';
import { useTranslation } from 'react-i18next';
import './ProductTitle.css'; // Import the corresponding CSS file

const TitlePage = () => {
  const { t } = useTranslation();
  return (
    <div className="product-overlay">
      <h1 className="product-us">{t('productTitle.title')}</h1>
      <p className="product-description">{t('productTitle.description')}</p>
      
    </div>
  );
};

export default TitlePage;
