import React from 'react';
import { useTranslation } from 'react-i18next';
import './ApplicationTitle.css'; // Import the corresponding CSS file

const TitlePage = () => {
  const { t } = useTranslation();
  return (
    <div className="Application-overlay">
      <h1 className="Application-us">{t('applicationTitle.title')}</h1>
      <p className="Application-description">{t('applicationTitle.description')}</p>
      
    </div>
  );
};

export default TitlePage;
