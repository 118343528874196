import React from 'react';
import Inform1 from './Df3_inform1';
import Inform2 from './Df3_inform2';
import Advantage from './Advantages';
import TablePage from './TablePage';
import CertificatePage from './CertificatePage';
import LearnPage from './LearnPage';
import ProductTitle from './ProductTitle';



const Df3 = () => {
  return (
    <div>
      <div className="inform-section">
        <br />
        <ProductTitle />
        <Inform1 />
        <Inform2 />
        <Advantage />
        <TablePage />
        <CertificatePage />
        <LearnPage />

      </div>
    </div>
  );
};

export default Df3;
