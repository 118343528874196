import React from 'react';
import { useTranslation } from 'react-i18next';
import './Advantages.css'; // Assuming the CSS is saved here
import { FaDollarSign, FaTachometerAlt, FaCogs, FaLeaf, FaLock, FaRoute,FaChartLine,FaTools ,FaCertificate,FaLightbulb,FaSync,FaStar} from 'react-icons/fa'; // Example icons

const AdvantagePage = () => {
  const { t } = useTranslation();
  const advantages = [
    { icon: <FaDollarSign />, title: t('advantages.icon1.title'), detail1: t('advantages.icon1.detail1') },
    { icon: <FaTachometerAlt />, title: t('advantages.icon2.title'), detail1: t('advantages.icon2.detail1') ,detail2: t('advantages.icon2.detail2'),detail3:t('advantages.icon2.detail3')  },
    { icon: <FaCogs />, title: t('advantages.icon3.title'), detail1: t('advantages.icon3.detail1') },
    { icon: <FaLeaf />, title: t('advantages.icon4.title'), detail1:t('advantages.icon4.detail1') },
    { icon: <FaLock />, title: t('advantages.icon5.title'), detail1: t('advantages.icon5.detail1') },
    { icon: <FaRoute />, title: t('advantages.icon6.title'), detail1: t('advantages.icon6.detail1') },
    { icon: <FaChartLine />, title: t('advantages.icon7.title'), detail1: t('advantages.icon7.detail1') },
    { icon: <FaTools />, title: t('advantages.icon8.title'), detail1: t('advantages.icon8.detail1')},
    { icon: <FaCertificate />, title: t('advantages.icon9.title'), detail1: t('advantages.icon9.detail1')},
    { icon: <FaLightbulb />, title: t('advantages.icon10.title'), detail1:t('advantages.icon10.detail1')},
    { icon: <FaSync />, title: t('advantages.icon11.title'), detail1: t('advantages.icon11.detail1')},
    { icon: <FaStar />, title: t('advantages.icon12.title'), detail1: t('advantages.icon12.detail1')},
  ];

  return (
    <div className="advantage-page">
      <h5 className="main-header">{t('advantages.header')}</h5>

      <div className="top-div">
        {advantages.map((adv, index) => (
          <div key={index} className="advantage-box">
            <div className="icon">{adv.icon}</div>
            <h2>{adv.title}</h2>
            <p>{adv.detail1}</p>
            <p>{adv.detail2}</p>
            <p>{adv.detail3}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdvantagePage;
