import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Application5.css'; // Import the CSS file

const Application = () => {
  const { t } = useTranslation();
  const [inView, setInView] = useState(false);
  const appRef = useRef(null);

  useEffect(() => {
    const currentRef = appRef.current; // Store the current ref value

    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.1 } // Adjust as needed
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Use the stored ref value in cleanup
      }
    };
  }, []);
  return (
    <div ref={appRef} className={`application5-page ${inView ? 'in-view' : ''}`}>
      {/* Left-side div with headline and text */}
      <div className="content-container">
        <div className="headline5">
          <h1>{t('application5.title')}</h1>
        </div>
        <div className="text5">
          <ul className="bullet-points">
            <li>
            <span className="highlight">{t('application5.point1.span')}</span>{t('application5.point1.text1')}<br /><br />{t('application5.point1.text2')}
            </li>
            <li>
            <span className="highlight">{t('application5.point2.span')}</span>{t('application5.point2.text1')}<br /><br />
            </li>
            <li>
              <span className="highlight">{t('application5.point3.span')}</span>{t('application5.point3.text1')}<br /><br />
            </li>
            <li>
              <span className="highlight">{t('application5.point4.span')}</span>{t('application5.point4.text1')}<br /><br />
            </li>
            <li>
              <span className="highlight">{t('application5.point5.span')}</span>{t('application5.point5.text1')}<br /> 
            </li>
          </ul>
        </div>
        <br />
      </div>
    </div>
  );
};

export default Application;
