// Inform2.jsx
import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Inform2.css'; // Ensure you create a corresponding CSS file for styles
import inform2Img from '../assets/inform2.png'; // Update path if needed

const Inform2 = () => {
  const informRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      if (informRef.current) {
        const rect = informRef.current.getBoundingClientRect();
        const inView = rect.top <= window.innerHeight && rect.bottom >= 0;
        informRef.current.classList.toggle('in-view', inView);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="inform2" ref={informRef}>
      <div className='inform2-left'>
        <div className='top-text'>
          <div className="inform2-text1-left">
            <h2>
            {t('inform2.head1.text1')}
            </h2>
          </div>
          <div className="inform2-text1-right">
            <h2>
            <span className="highlight">{t('inform2.head2.span1')}</span><br />
            <span className="highlight">{t('inform2.head2.span2')}</span><br />
            <span className="highlight">{t('inform2.head2.span3')}</span><br />
            {t('inform2.head2.span4')}<br />
            </h2>
          </div>
        </div>
        <div className='down-text'>
          <h2>{t('inform2.head3.text1')}<br />
          {t('inform2.head3.text2')}<br />
          {t('inform2.head3.text3')}<br />
          </h2>
        </div>
      </div>
      <div className="inform2-right">
        <img src={inform2Img} alt="Innovative Gyrocopter" />
      </div>
    </div>
  );
};

export default Inform2;
