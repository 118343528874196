import React, { useRef, useEffect, useState } from 'react';
import './Inform1.css'; // Ensure this is the correct path
import { useTranslation } from 'react-i18next';
import Map from '../assets/map.png';

const Inform = () => {
  const [inView, setInView] = useState(false);
  const informRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const currentRef = informRef.current; // Store the current ref value

    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.1 } // Adjust as needed
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Use the stored ref value in cleanup
      }
    };
  }, []);

  return (
    <section ref={informRef} className={`inform ${inView ? 'in-view' : ''}`}>
      <div className="inform-left">
        <h1>{t('inform1.title')}</h1>
        <h2>{t('inform1.subtitle')}</h2>
        <hr />
        <ul className="bullet-points">
          <li class="bullet-1">
          {t('inform1.point1.text1')}<span className="highlight">{t('inform1.point1.span')}</span> {t('inform1.point1.text2')}
          </li>
          <li class="bullet-2">
            <span className="highlight">{t('inform1.point2.span')}</span> {t('inform1.point2.text')}
          </li>
          <li class="bullet-3">
            <span className="highlight">{t('inform1.point3.span')}</span> {t('inform1.point3.text')}
          </li>
          <li class="bullet-4">
            <span className="highlight">{t('inform1.point4.span1')}</span> {t('inform1.point4.text1')}<br />
            <span className="highlight">{t('inform1.point4.span2')}</span> {t('inform1.point4.text2')}<br />
            <span className="highlight">{t('inform1.point4.span3')}</span> {t('inform1.point4.text3')}<br />
          </li>
        </ul>
      </div>
      <div className="inform-right">
        <img src={Map} alt="inform1" />
      </div>
    </section>
  );
};

export default Inform;
